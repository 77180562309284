import React from "react";
import { useParams } from "react-router-dom";
import projectData from "./projectData";
import { Container, Row, Col, Image, Button, Carousel } from "react-bootstrap";
import { BsFillPlayFill, BsGithub } from "react-icons/bs";
import Particle from "../Particle";
import "./projectDetail.css";

function ProjectDetail() {
  const { id } = useParams();
  const project = projectData.find((proj) => proj.id === parseInt(id));

  if (!project) {
    return <h2>Project not found</h2>;
  }

  return (
    <Container fluid className="project-detail-section">
      <div className="particle-container">
        <Particle />
      </div>
      <Container>
        <h1 className="project-headings">{project.title}</h1>
        <Row className="justify-content-center">
          <Col md={8}>
            <Carousel
              style={{
                maxWidth: "800px",
                margin: "0 auto",
              }}
            >
              {project.photos.map((photo, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={photo}
                    alt={`Slide ${index + 1}`}
                    style={{
                      borderRadius: "10px",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col md={8}>
            <div className="project-info">
              {/* <h2 className="section-title">Project Details</h2> */}
              <p>
                <strong className="section-title">Title:</strong>{" "}
                {project.client}
              </p>
              <p>
                <strong className="section-title">Duration:</strong>{" "}
                {project.duration}
              </p>
              <p>
                <strong className="section-title">Client/Organization:</strong>{" "}
                {project.client}
              </p>
              <p>
                <strong className="section-title">Overview:</strong>{" "}
                {project.description}
              </p>
              <p>
                <strong className="section-title">Technologies Used:</strong>
              </p>
              <ul>
                {project.technologies.map((tech, index) => (
                  <li key={index}>
                    <strong>{tech.name}:</strong> {tech.value}
                  </li>
                ))}
              </ul>
              <p>
                <strong className="section-title">Your Role:</strong>{" "}
                {project.role}
              </p>
              <p>
                <strong className="section-title">
                  Challenges and Solutions:
                </strong>{" "}
                {project.challenges}
              </p>
              <p>
                <strong className="section-title">Outcome/Results:</strong>{" "}
                {project.outcome}
              </p>
              <p>
                <em>
                  *The client name has been replaced due to privacy reasons.
                </em>
              </p>
              {/* <div className="buttons">
                {project.ghLink && (
                  <Button variant="primary" href={project.ghLink} target="_blank">
                    <BsGithub /> &nbsp; GitHub
                  </Button>
                )}
                {!project.isBlog && project.demoLink && (
                  <Button variant="primary" href={project.demoLink} target="_blank" className="demo-button">
                    <BsFillPlayFill /> &nbsp; Live Demo
                  </Button>
                )}
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default ProjectDetail;
