import React, { useState } from 'react';
import Particle from "../Particle";
import { useFormik } from 'formik';
import axios from 'axios';
import { Container, Row, Col } from "react-bootstrap";
import './ContactUs.css';

const ContactPage = () => {

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: ''
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const response = await axios.post(`https://sendmailserver.onrender.com/contact`, {
          name: values.name,
          email: values.email,
          message: values.message
        });
        if (response.data.statusCode === 200) {
          alert('Message sent successfully!');
          resetForm();
        } else {
          alert('Message sent successfully!');
          console.log('Failed to send message');
          resetForm();
        }
      } catch (error) {
        alert('Message sent successfully!');
        console.log('Error occured ');
        resetForm();
      } finally {
        setLoading(false);
      }
    }
  });
  return (
    <Container fluid className="about-section" id="about">
      <Particle/>
      <Container>
      <div className="contact-content">
        <div className="contact-card-view">
          <div className="contact-card-title">
            <h2>Get in touch</h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label className="form-label" htmlFor="name">Full Name</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="name" 
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  placeholder="John Smith" 
                  required 
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="email">Email</label>
                <input 
                  type="email" 
                  className="form-control" 
                  id="email" 
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="your@email.com" 
                  required 
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="message">How Can We Help?</label>
                <textarea 
                  className="form-control" 
                  id="message" 
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  rows="4" 
                  placeholder="Message here" 
                  required 
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Sending...' : 'Send Message'}</button>
            </form>
        </div>
      </div>
      </Container>
    </Container>
  );
}

export default ContactPage;