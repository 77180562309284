import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import myImg from "../../Assets/newhomephoto.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import SkillsStack from "./SkillsStack";

function Home2() {
  let navigate = useNavigate();
  const [click, setClick] = useState(false);
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={3} className="myAvtar" style={{ marginRight: "100px" }}>
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Let us <span className="purple"> Introduce </span> ourself
            </h1>
            <p className="home-about-body">
              We are a power coding couple from India with expertise in{" "}
              <b className="purple">Wordpress, Javascript, and C#</b>.
              <br />
              <br />
              Our portfolio showcases a commitment to creating beautiful,
              efficient, and reliable software solutions that cater to both
              organizations and individuals.
              <br />
              <br />
              With a passion for development, we also love to work in{" "}
              <b className="purple">Node.js</b>, and leverage modern JavaScript
              libraries and frameworks such as{" "}
              <b className="purple">React.js</b> and{" "}
              <b className="purple">Next.js</b> to build cutting-edge products.
              <br />
              <br />
              Our skill set positions us at the forefront of software
              development, ready to tackle complex challenges and deliver
              top-tier digital experiences.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            {/* <h1>FIND ME ON</h1>
        
          <ul className="home-about-social-links">
            <li className="social-icons">
              <a
                href="https://github.com/soumyajit4419"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://twitter.com/Soumyajit4419"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/soumyajit4419/"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/soumyajit4419"
                target="_blank"
                rel="noreferrer"
                className="icon-colour home-social-icons"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul> */}

            {/* <p>
            Feel free to <span className="purple">connect </span>with me
          </p>
          {click ? (
  <Card className="contact-card-view mt-3">
    <Card.Body>
      <h3 className="contact-card-title">Contact Us</h3>
      <Form>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label className="form-label">Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label className="form-label">Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formMessage">
          <Form.Label className="form-label">Message</Form.Label>
          <Form.Control as="textarea" rows={3} className="form-control" />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </Card.Body>
  </Card>
) : (
  <Button onClick={() => navigate("/contact")}>Contact Us</Button>
)} */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
