import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
           
            <strong className="purple">Our Superpowers</strong>
            <br />
            <br />
            <span>Programming Languages</span>
            <br />
            <br/>
            <ul>
              <li className="about-activity">
                 <b className="purple"> <ImPointRight /> Angular </b>: We excel in Angular, creating dynamic web applications that engage users.
              </li>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> React </b>: Our proficiency in React allows us to build responsive and interactive interfaces.
              </li>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> Wordpress </b>: We specialize in developing custom themes and plugins, transforming websites into extraordinary experiences.
              </li>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> .NET </b>: The .NET framework is our reliable tool for developing robust backend systems.
              </li>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> Node js </b>: We utilize Node.js to build scalable, high-performance applications.
              </li>
            </ul>
            <br />
            <span>Design Aesthetics</span>
            <br />
            <br />
            <ul>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> UI/UX Design </b>: Our design skills ensure intuitive and user-friendly interfaces.
              </li>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> Logo Design </b>: We create distinctive and memorable brand identities through our logo designs.
              </li>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> Brand Management </b>: We develop cohesive brand experiences, from color palettes to brand guidelines.
              </li>
            </ul>
            <br />
            <strong className="purple">Our Story</strong>
            <br />
            <br />
            Our partnership extends beyond coding; it’s about a connection that enhances both our personal and professional lives. We collaborate seamlessly, solving problems and optimizing our relationship for success. Outside of work, we enjoy discussing algorithms over coffee and envisioning new projects together.
            <br />
            <br />
            <strong className="purple">Why Choose Us?</strong>
            <br />
            <br />
            <ul>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> Collaborative Efficiency </b>: Two minds working in synergy allow us to address challenges swiftly and deliver high-quality results.
              </li>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> Technical and Creative Fusion </b>: Our unique combination of technical expertise and creative insight sets us apart.
              </li>
              <li className="about-activity">
              <b className="purple"> <ImPointRight /> Passion-Driven Projects </b>: Our love for what we do is evident in every project we undertake, ensuring each one is a true labor of love.
              </li>
            </ul>
            <br />
            <strong className="purple">Let’s Collaborate</strong>
            <br />
            <br/>
            Whether you need a web application, an elegant user interface, or a comprehensive brand overhaul, we are ready to bring your vision to life. Let’s work together to create something extraordinary.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
