import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { BsFillPlayFill, BsGithub } from "react-icons/bs";
import "./ProjectCard.css";

function ProjectCard(props) {
  return (
    <Card className="project-card-view">
    <Card.Img
      variant="top"
      src={props.imgPath}
      alt="card-img"
      className="card-img-radius" // Add the CSS class
    />
    <Card.Body className="card-body-flex">
      <div>
        <Card.Title className="card-title">{props.client}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.content}
        </Card.Text>
      </div>
      <div className="mt-auto">
        {props.ghLink && (
          <Button variant="primary" href={props.ghLink} target="_blank">
            <BsGithub /> &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
          </Button>
        )}
        {"\n"}
        {"\n"}
        <Link to={`/project/${props.id}`}>
          <Button variant="primary" style={{ marginLeft: "10px", marginBottom: '10px' }}>
            
            {props.button}
          </Button>
        </Link>
      </div>
    </Card.Body>
  </Card>
  );
}

export default ProjectCard;
