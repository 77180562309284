import financial1 from "../../Assets/Projects/financial1.jpg";
import financial2 from "../../Assets/Projects/financial2.jpg";
import financial3 from "../../Assets/Projects/financial3.jpg";
import naturalnurture1 from "../../Assets/Projects/naturalnurture1.jpg";
import naturalnurture2 from "../../Assets/Projects/naturalnurture2.jpg";
import naturalnurture3 from "../../Assets/Projects/naturalnurture3.jpg";
import djfaint1 from "../../Assets/Projects/djfaint1.jpg";
import djfaint2 from "../../Assets/Projects/djfaint2.jpg";
import djfaint3 from "../../Assets/Projects/djfaint3.jpg";
import beautyspot1 from "../../Assets/Projects/beautyspot1.jpg";
import beautyspot2 from "../../Assets/Projects/beautyspot2.jpg";
import beautyspot3 from "../../Assets/Projects/beautyspot3.jpg";
import jupiter1 from "../../Assets/Projects/jupiter1.jpg";
import jupiter2 from "../../Assets/Projects/jupiter2.jpg";
import jupiter3 from "../../Assets/Projects/jupiter3.jpg";
import hired1 from "../../Assets/Projects/hired1.jpg";
import hired2 from "../../Assets/Projects/hired2.jpg";
import hired3 from "../../Assets/Projects/hired3.jpg";
import CRM1 from "../../Assets/Projects/CRM1.png";
import CRM2 from "../../Assets/Projects/CRM-2.png";
import CRM3 from "../../Assets/Projects/CRM-3.png";
import CRM4 from "../../Assets/Projects/CRM-4.png";
import bspot1 from "../../Assets/Projects/bspot1.jpg";
import bspot2 from "../../Assets/Projects/bspot2.jpg";
import bspot3 from "../../Assets/Projects/bspot3.jpg";
import tour1 from "../../Assets/Projects/tour1.png";
import tour2 from "../../Assets/Projects/tour2.png";
import tour3 from "../../Assets/Projects/tour3.png";
import tour4 from "../../Assets/Projects/tour4.png";

const projectData = [
  {
    id: 1,
    title: "Mentor Consultancy Website Development",
    duration: "July 2023 - August 2023",
    client: "Mentor Consultancy",
    imgPath: financial1,
    content:
      "Created a professional WordPress website for Mentor Consultancy, showcasing their HR, finance, marketing, business, and sales services. Customized the theme, integrated advanced contact forms, and optimized for SEO, leading to increased client inquiries and improved online visibility.",
    description:
      "The goal of this project was to design and develop a professional and engaging website for Mentor Consultancy, a firm providing a wide range of consultancy services including HR, finance, marketing, business strategy, and sales. The website needed to effectively communicate the breadth and depth of their services, showcase client testimonials, and feature a blog section for sharing industry insights and updates.",
    technologies: [
      { name: "Platform", value: "WordPress" },
      { name: "Design", value: "Elementor, CSS" },
      {
        name: "Other",
        value:
          "Contact Form 7 for inquiries, Yoast SEO for search engine optimization, WP Super Cache for performance optimization",
      },
    ],
    role: "As the lead web developer, we were responsible for the comprehensive development of the website. This involved customizing the WordPress theme to align with the company's branding, integrating various functionalities to enhance user experience, and optimizing the site for performance and SEO.",
    challenges:
      "A significant challenge was structuring the website to clearly present the diverse range of consultancy services offered by Mentor Consultancy. To address this, we designed intuitive navigation and service pages, each with detailed descriptions and relevant case studies. Additionally, we implemented an advanced contact form to streamline client inquiries and integrated a blog section to keep the content dynamic and engaging.",
    outcome:
      "The Mentor Consultancy website was successfully launched within the projected timeline. The client reported a marked increase in online inquiries and positive feedback regarding the site's professional appearance and ease of use. The integrated SEO strategies led to improved visibility on search engines, resulting in a higher volume of organic traffic. The client was very satisfied with the final product, which has significantly enhanced their online presence and client engagement.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [financial1, financial2, financial3],
  },
  {
    id: 2,
    title: "Natural Nature Wellness Website Development",
    duration: "January 2023 - April 2023",
    client: "Natural Nature Wellness",
    imgPath: naturalnurture1,
    content:
      "Developed a WordPress-based e-commerce website for Natural Nature Wellness, enhancing online sales and user engagement. Implemented WooCommerce for product management, optimized site performance, and improved SEO, resulting significant increase in sales post-launch.",
    description:
      "The goal of this project was to design and develop a modern, user-friendly website for Natural Nature Wellness, a company specializing in wellness products. The website needed to showcase their product offerings, provide a seamless shopping experience, and incorporate informative content related to health and wellness.",
    technologies: [
      { name: "Platform", value: "WordPress" },
      { name: "Design", value: "WooCommerce" },
      { name: "E-commerce", value: "Elementor, CSS" },
      {
        name: "Other",
        value:
          "MailChimp for email marketing, Yoast SEO for search engine optimization",
      },
    ],
    role: "As the lead web developers, We were responsible for the end-to-end development of the website. This included customizing the WordPress theme, integrating e-commerce functionalities using WooCommerce, and ensuring the site was optimized for performance and SEO.",
    challenges:
      "One of the primary challenges was creating a visually appealing and intuitive shopping experience while maintaining site performance. To overcome this, we used a lightweight WordPress theme and optimized all images and scripts. Additionally, integrating WooCommerce required careful configuration to handle various product categories and payment gateways, which we successfully managed through custom plugins and extensions.",
    outcome:
      "The Natural Nature Wellness website was completed on schedule and met all client requirements. The new site resulted in a significant increase in online sales within the first quarter post-launch. User feedback was overwhelmingly positive, particularly regarding the ease of navigation and the aesthetic appeal of the site. The integrated SEO strategies also led to a noticeable improvement in search engine rankings, driving more organic traffic to the site.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [naturalnurture1, naturalnurture2, naturalnurture3],
  },
  {
    id: 3,
    title: "DJ Faint Website Designing",
    duration: "August 2018 - January 2019",
    client: "DJ Faint (Miami-based DJ)",
    imgPath: djfaint1,
    content:
      "The project aimed to create a visually striking and user-friendly website for DJ Faint, a up and coming DJ based in Miami. The website needed to facilitate ticket booking, seat selection, and payment processing, catering to the specific needs of DJ Faint's audience.",
    description:
      "The project aimed to create a visually striking and user-friendly website for DJ Faint, a up and coming DJ based in Miami. The website needed to facilitate ticket booking, seat selection, and payment processing, catering to the specific needs of DJ Faint's audience. The design process focused on creating an engaging and intuitive user experience through comprehensive UX research and iterative design.",
    technologies: [
      { name: "UX Tools", value: "InVision, Photoshop" },
      { name: "Design", value: "Figma" }
    ],
    role: "As the UX/UI designer, we was responsible for conducting extensive UX research to understand user needs and behaviors. We designed the website using Figma, creating wireframes, prototypes, and final UI designs. We also developed complex user flows for ticket booking, seat selection, and payment processing, ensuring a smooth and enjoyable user experience.",
    challenges:
      "One of the main challenges was designing a seamless and intuitive ticket booking system that included detailed seat selection and secure payment processing. To address this, we conducted user interviews and usability tests to identify pain points and optimize the flow. Additionally, we collaborated closely with developers to ensure the design was feasible and aligned with technical requirements.",
    outcome:
      "The DJ Faint website was successfully launched, receiving positive feedback for its aesthetic appeal and user-friendly design. The complex booking and payment flows were well-received, resulting in a high conversion rate for ticket sales. The client reported increased engagement and satisfaction from users, attributing it to the well-researched and meticulously designed user experience.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [djfaint1, djfaint2, djfaint3],
  },
  {
    id: 4,
    title: "Beauty Spot Beauty Salon Mobile Design",
    duration: "March 2018 - May 2018",
    client: "Beauty Spot Beauty Salon",
    imgPath: beautyspot1,
    content:
      "The aim of this project was to design a mobile-only website for Beauty Spot, a small beauty salon. The website needed to facilitate appointment bookings and product/service selections efficiently on mobile devices. The design process included extensive UX research, creation of personas, prototyping, and gathering user feedback to ensure an intuitive and engaging user experience.",
    description:
      "The aim of this project was to design a mobile-only website for Beauty Spot, a small beauty salon. The website needed to facilitate appointment bookings and product/service selections efficiently on mobile devices. The design process included extensive UX research, creation of personas, prototyping, and gathering user feedback to ensure an intuitive and engaging user experience.",
      technologies: [
        { name: "UX Tools", value: "InVision, Photoshop" },
        { name: "Design", value: "Figma" }
      ],
    role: "As the UX/UI designer, we was responsible for the full UX process, including conducting user research, creating personas, and developing prototypes. Using Figma, we designed wireframes and the final UI for the mobile website. The project included creating complex user flows for booking appointments and selecting products and services, ensuring a smooth and user-friendly experience on mobile devices.",
    challenges:
      "Designing a seamless and intuitive mobile interface for booking appointments and selecting products/services was a significant challenge. To overcome this, we conducted user interviews and usability tests to identify pain points and optimize the user flows. By creating detailed personas and iterating on prototypes based on user feedback, we ensured the design met the needs and expectations of the salon's clientele.",
    outcome:
      "The mobile website for Beauty Spot was successfully launched, receiving positive feedback for its design and usability. The intuitive appointment booking and product/service selection flows led to increased online bookings and customer satisfaction. The client reported enhanced user engagement and satisfaction, attributing the success to the thorough UX research and thoughtful design process.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [beautyspot1, beautyspot2, beautyspot3],
  },
  {
    id: 5,
    title: "Jupiter Associates Logo Design",
    duration: "July 2023 - October 2023",
    client: "Jupiter Associates",
    imgPath: jupiter1,
    content:
      "Designed a logo for Jupiter Associates, an investment company, using Illustrator and Photoshop. The project involved market research and explored both traditional and modern design approaches to create a distinctive and professional logo.",
    description:
      "The objective of this project was to design a professional and distinctive logo for Jupiter Associates, an investment company. The design process included market research, conceptualization, and the exploration of both traditional and modern design approaches. The aim was to create a logo that reflects the company’s values and appeals to its target audience.",
    technologies: [
      { name: "Design Tools", value: "Adobe Illustrator, Adobe Photoshop" },
    ],
    role: "As the designer, we were responsible for conducting market research to understand industry trends and the competitive landscape. we developed multiple logo concepts using both traditional and modern design approaches. The process involved creating initial sketches, refining selected designs, and producing final versions using Illustrator and Photoshop. we also gathered feedback from stakeholders to ensure the logo met their expectations and effectively represented the company’s brand.",
    challenges:
      "A key challenge was balancing the traditional and modern elements to create a logo that was both timeless and contemporary. To address this, we created detailed mood boards and conducted competitive analysis to identify successful design elements in the industry. we iteratively refined the logo concepts based on stakeholder feedback and market insights, ensuring the final design was both unique and representative of Jupiter Associates’ brand identity.",
    outcome:
      "The final logo design for Jupiter Associates was well-received by the client and stakeholders, who appreciated its professional and modern aesthetic. The logo effectively communicated the company’s brand values and stood out in the competitive investment market. The comprehensive research and iterative design process ensured that the logo met all the client’s requirements and resonated with their target audience.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [jupiter1, jupiter2, jupiter3],
  },
  {
    id: 6,
    title: "Be-Hire Web Application Development",
    duration: "January 2023 - August 2023",
    client: "Be-Hire",
    imgPath: hired1,
    content:
      "Developed a web application with a team using Angular, .NET Core, and Entity Framework. The app facilitates part-time job hiring, job posting, invoicing, and hours tracking. Comprehensive UX processes ensured an efficient user experience.",
    description:
      "The Be-Hire project aimed to create a web application enabling users to hire part-time workers or post job listings. The application included functionalities for job posting, applicant selection, invoicing, and hours tracking. The development focused on creating a seamless user experience through detailed UX processes including research, personas, and feedback.",
    technologies: [
      { name: "Frontend", value: "Angular" },
      { name: "Backend", value: ".NET Core" },
      {
        name: "Database",
        value:
          "Entity Framework",
      },
    ],
    role: "As developers, we collaborated with a team to build the application. Our responsibilities included implementing frontend features using Angular, developing backend services with .NET Core, and integrating the Entity Framework for database management. We also participated in UX research and testing to ensure a user-friendly interface.",
    challenges:
      "One challenge was creating a comprehensive yet intuitive system for job posting and hiring. To address this, we conducted user research and iterative testing to refine the user flows. We also integrated a robust invoicing and hours tracking system, ensuring accuracy and reliability in financial transactions.",
    outcome:
      "The Be-Hire web application was successfully launched, receiving positive feedback for its functionality and user experience. The application streamlined the process of hiring part-time workers and managing job postings, significantly improving efficiency for users. It resulted in increased user engagement and satisfaction, meeting all client expectations.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [hired1, hired2, hired3],
  },
  {
    id: 7,
    title: "Beauty Spot Brand Management",
    duration: "May 2019 - April 2023",
    client: "Beauty Spot Beauty Salon",
    imgPath: bspot1,
    content:
      "The Beauty Spot brand management project focused on establishing and enhancing the brand identity of a beauty salon over a four-year period. The project encompassed managing the salon's social media presence, creating engaging content, and running targeted advertising campaigns to attract and retain customers.",
    description:
      "The Be-Hire project aimed to create a web application enabling users to hire part-time workers or post job listings. The application included functionalities for job posting, applicant selection, invoicing, and hours tracking. The development focused on creating a seamless user experience through detailed UX processes including research, personas, and feedback.",
    technologies: [
      { name: "Social Media Platforms", value: "Instagram, Facebook, Twitter" },
      { name: "Design Tools", value: "Adobe Photoshop, Canva" },
      {
        name: "Advertising Platforms",
        value:
          "Facebook Ads, Instagram Ads, Google Ads",
      },
    ],
    role: "As the brand manager, we were responsible for developing and curating the brand identity of Beauty Spot. Our tasks included managing the salon’s social media accounts, creating visually appealing and engaging social media posts, and running photo and video ad campaigns. We conducted market research to align the brand’s image with current trends and customer preferences, ensuring consistent and effective brand communication.",
    challenges:
      "A significant challenge was maintaining a consistent and appealing brand image across various platforms while adapting to changing social media trends. To address this, we developed a comprehensive brand strategy that included a content calendar, regular engagement with followers, and data-driven adjustments to our campaigns. By analyzing the performance of posts and ads, we were able to refine our approach and maximize reach and engagement.",
    outcome:
      "The brand management efforts led to a substantial increase in Beauty Spot's social media following and customer engagement. The ad campaigns successfully attracted new clients and retained existing ones, contributing to the salon’s growth. The cohesive and attractive brand identity established over this period significantly enhanced Beauty Spot’s market presence and reputation.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [bspot1, bspot2, bspot3],
  },
  {
    id: 8,
    title: "Cloud Rental Manager Website Development",
    duration: "August 2023 - April 2024",
    client: "Cloud Rental Manager",
    imgPath: CRM1,
    content:
      "Developed a comprehensive property rental management web application with a team using React, Node js, and Mongodb. The app facilitates property listings, tenant management, rent collection, and maintenance requests. Extensive UX processes ensured an efficient user experience.",
    description:
      "The Cloud Rental Manager project aimed to create a web application enabling users to manage rental properties efficiently. The application included functionalities for property listings, tenant onboarding, rent payment processing, and maintenance tracking. The development focused on delivering a seamless user experience through detailed UX processes including research, personas, and feedback",
    technologies: [
      { name: "Frontend", value: "React" },
      { name: "Backend", value: "Node js" },
      {
        name: "Database",
        value:
          "Mongodb",
      },
    ],
    role: "As developers, we collaborated with a team to build the application. Our responsibilities included implementing frontend features using React, developing backend services with Node js, and integrating the Mongodb for database management. We also participated in UX research and testing to ensure a user-friendly interface.",
    challenges:
      "One challenge was creating a comprehensive yet intuitive system for property management. To address this, we conducted user research and iterative testing to refine the user flows. We also integrated a robust rent collection and maintenance tracking system, ensuring accuracy and reliability in financial transactions and property upkeep.",
    outcome:
      "The Cloud Rental Manager web application was successfully launched, receiving positive feedback for its functionality and user experience. The application streamlined the process of managing rental properties and interacting with tenants, significantly improving efficiency for users. It resulted in increased user engagement and satisfaction, meeting all client expectations.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [CRM1, CRM2, CRM3, CRM4],
  },
  {
    id: 9,
    title: "World Tour Website Development",
    duration: "May 2023 - October 2023",
    client: "World Tour",
    imgPath: tour1,
    content:
      "Developed a comprehensive tour booking web application with a team using React, Node.js, and MongoDB. The app facilitates tour listings, booking management, payment processing, and customer support. Extensive UX processes ensured an efficient user experience.",
    description:
      "The World Tour project aimed to create a web application enabling users to book tours efficiently. The application included functionalities for tour listings, booking management, payment processing, and customer support. The development focused on delivering a seamless user experience through detailed UX processes including research, personas, and feedback.",
    technologies: [
      { name: "Frontend", value: "React" },
      { name: "Backend", value: "Node.js" },
      {
        name: "Database",
        value: "MongoDB"
      }
    ],
    role: "As developers, we collaborated with a team to build the application. Our responsibilities included implementing frontend features using React, developing backend services with Node.js, and integrating MongoDB for database management. We also participated in UX research and testing to ensure a user-friendly interface.",
    challenges:
      "One challenge was creating a comprehensive yet intuitive system for tour booking and management. To address this, we conducted user research and iterative testing to refine the user flows. We also integrated a robust payment processing system, ensuring accuracy and reliability in financial transactions.",
    outcome:
      "The World Tour web application was successfully launched, receiving positive feedback for its functionality and user experience. The application streamlined the process of booking tours and managing reservations, significantly improving efficiency for users. It resulted in increased user engagement and satisfaction, meeting all client expectations.",
    // ghLink: "https://github.com/your-repo",
    // demoLink: "https://mentor-consultancy-demo.com",
    isBlog: false,
    photos: [tour1, tour2, tour3, tour4]
}

];

export default projectData;
