import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/aboutphoto.png";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>

        <Row style={{ padding: "10px", paddingBottom: "0px", paddingTop: "30px", alignItems:'flex-start', justifyContent: 'flex-start' }}>
          <Col 
             md='6'
             style={{ paddingTop: "10px", paddingBottom: "50px", marginRight:"50px" }}
             className="about-img">
               <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Explore our <strong className="purple">Identity</strong>
            </h1>
            <br/>
            <br/>
            <blockquote className="blockquote mb-0">
            <p style={{ textAlign: "justify" }}>
               We are <span className="purple">Basu & Mansi</span>, a dedicated software developer duo with a shared passion for coding and a strong personal bond. Based in  
            <span className="purple"> Bhavnagar, Gujarat, India.</span> We combine our individual expertise to deliver exceptional digital solutions.
            <br />
            <br />
            <b className="purple">Basu </b> holds a Bachelor of Engineering (BE) degree from GEC, Bhavnagar, and has over four years of professional experience.

            <br />
            <b className="purple">Mansi </b> holds a Master of Computer Application (MCA) degree from GMIT, Bhavnagar, with over one year of professional experience.
            
            </p>
            </blockquote>
          </Col>
        <Col
            md='5'
            style={{ paddingBottom: "50px" }}
            className="about-img"
          >
              
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
          </Row>
        <Row style={{ padding: "10px", paddingBottom: "80px", paddingTop: "0px", alignItems:'flex-start', justifyContent: 'flex-start' }}>
          <Col

            style={{
              justifyContent: "flex-start",
              paddingTop: "30px",
              paddingBottom: "50px",
              textAlign: 'left'
            }}
          >
            <Aboutcard />
          </Col>
         
        </Row>
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading pt-5">
          <strong className="purple">Tools</strong> We Use
        </h1>
        <Toolstack />
      </Container>
    </Container>
  );
}

export default About;


